<template>
  <div class="about">
    <h1>This is an about page</h1>
    <h2>{{ title }}</h2>
    <h2>{{ capTitle }}</h2>
    <h3>{{ saucisse('cuisine') }}</h3>
    <input type="text" v-model="uinput" />
  </div>
</template>


<script>
import { ref, computed } from 'vue'

export default {
  name: 'About',
  props: {
    title: {
      type: String
    }
  },
  setup () {
    const uinput = ref('')
    const capTitle = computed(() => uinput.value ? uinput.value.toUpperCase() : '')
    const plouf = ref('lol')

    const saucisse = x => `${capTitle.value}...Saucisse ${x} !`

    return {
      plouf,
      capTitle,
      saucisse,
      uinput
    }
  }
}
</script>